<template>
  <div class="login-content" v-html="privacyTerm"></div>
</template>

<script>
import PrivacyTerm from '@/utils/default-privacy-term';
export default {
  data() {
    return {
      privacyTerm: PrivacyTerm
    };
  }
};
</script>
