<template>
  <div class="main-content">
    <div class="content-block">
      <div class="container">
        <PrivactTerm class="privacy-content"></PrivactTerm>
      </div>
    </div>
  </div>
</template>
<script>
import PrivactTerm from '@/components/PrivacyTerm';
export default {
  components: {
    PrivactTerm
  }
};
</script>
